<template>
<!-- main -->
<main id="container">

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <!-- <div class="contents">
                <p class="tit_contents">FA가 고객에게 가장 자신감있게 서비스할 수<br> <strong>든든한 지원 인프라를 구축</strong>하고 있으며<br> FA의 경쟁력을 뒷받침할 <strong>7대 영업지원<br> 시스템을 운영</strong>하고 있습니다. </p>
                <div class="btn_area">
                    <button class="anchor" data-target="#edu" type="button"><span>교육지원</span><img src="" data-images-path="/image/svg/next02.svg" alt="다음"></button>
                    <button class="anchor" data-target="#promote" type="button"><span>광고·홍보 지원</span><img src="" data-images-path="/image/svg/next02.svg" alt="다음"></button>
                    <button class="anchor" data-target="#alliance" type="button"><span>상품제휴</span><img src="" data-images-path="/image/svg/next02.svg" alt="다음"></button>
                    <button class="anchor" data-target="#network" type="button"><span>전문가 네트워크</span><img src="" data-images-path="/image/svg/next02.svg" alt="다음"></button>
                    <button class="anchor" data-target="#seminar" type="button"><span>고객세미나 지원</span><img src="" data-images-path="/image/svg/next02.svg" alt="다음"></button>
                    <button class="anchor" data-target="#computer" type="button"><span>전산 시스템</span><img src="" data-images-path="/image/svg/next02.svg" alt="다음"></button>
                    <button class="anchor" data-target="#system" type="button"><span>DB제공 시스템</span><img src="" data-images-path="/image/svg/next02.svg" alt="다음"></button>
                </div>
            </div> -->
            <div class="bg">
                <h3 class="tit_section"><span>FA가 가장 행복한 회사</span><br>피플라이프<p class="cmt">No.1 금융판매전문회사</p></h3>
            </div>
        </div>
       
    </section>
    <!-- 메인비주얼 -->


    <!-- 인터뷰 -->
    <section id="sevenfa" class="section">
        <div class="success_story">
            <div class="inner">
                <h3 class="tit_section">
                    <strong>피플라이프 성공사례 인터뷰</strong>
                    <span>피플라이프의 전문적인 경력개발, 교육체계 통해 성장한 피플人 들의 성공 스토리 인터뷰</span>
                </h3>
                <div class="top">
                    <div class="tab_buttons">
                        <button type="button" class="tab_btn"><span data-text="idName"></span></button>
                    </div>
                    <div class="first_story">
                        <div>
                            <a href="javascript:void(0)">
                                <div class="thumb">
                                    <img src="" alt="썸네일">
                                    <i class="ico_play"></i>
                                    <div id="video"></div>
                                </div>
                                <div class="desc_cnt">
                                    <p class="title"><span></span></p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="list">
                    <ul class="stroy_list">
                        <li>
                            <a href="javascript:void(0)" data-attr-title="title" data-type="youtube" title="영상재생">
                                <div class="thumb">
                                    <img src="" alt="썸네일">
                                    <i class="ico_play"></i>
                                </div>
                                <div class="desc_cnt">
                                    <p class="title"><span data-text="title" data-clamp="2"></span></p>
                                    <div class="desc"><span data-text="descriptionMemo" data-clamp="1"></span></div>
                                </div>
                            </a>
                        </li>
                    </ul>
                    <div class="more_wrap">
                        <button class="stroymore_btn" title="더보기" type="button"><img src="" data-images-path="/image/svg/plus02.svg" alt=""></button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- 인터뷰 -->

    <!-- 교육지원 -->
    <section id="edu" class="section">
        <h3 class="main_title">
            <strong>FA 7대 영업지원 시스템</strong>
            <span>FA가 고객에게 가장 자신감 있게 서비스 할 수 있도록 든든한 지원 인프라를 구축하고 있으며,<br> FA의 경쟁력을 뒷받침 할 7대 영업지원 시스템을 운영하고 있습니다.</span>
        </h3>
        <div class="inner">
            <h3 class="tit_section"><em>01</em><span>교육지원</span></h3>
            <ul class="list_edu">
                <li class="item_edu">
                    <div class="info">
                        <p class="tit">법인영업교육</p>
                        <p class="desc">법인 대상 컨설팅 영업을 조직화하여 사업적 비전과 품격을<br> 한 단계 업그레이드한 원동력은 바로 체계적인 교육의 힘입니다.</p>
                        <button class="btn_info" type="button"><span>교육과정 상세</span><img src="" data-images-path="/image/svg/plus01.svg" alt=""></button>
                    </div>
                    <div class="box_thumb">
                        <img src="/img/recruit/fa/edu_thumb1.jpg" alt="교육현장">
                    </div>

                    <div class="group_detail">
                        <table class="table table-bordered">
                            <caption></caption>
                            <colgroup>
                                <col style="width:auto;">
                                <col style="width:auto;">
                                <col style="width:auto;">
                                <col style="width:40%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th scope="col">교육과정</th>
                                    <th scope="col">구분</th>
                                    <th scope="col">교육대상</th>
                                    <th scope="col">교육내용</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row"><strong>10일</strong> 신입교육</th>
                                    <td>법인영업부문</td>
                                    <td>법인영업 신입 FA</td>
                                    <td class="tal">
                                        <ul>
                                            <li>· 법인운영과 개인사업자 법인전환 컨설팅 </li>
                                            <li>· 법인세법, 소득세법, 상법 등 이론과 프로세스 습득</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" rowspan="2"><strong>월간</strong> 정기교육</th>
                                    <td>주간정기교육</td>
                                    <td>법인영업사업단</td>
                                    <td class="tal">
                                        <ul>
                                            <li>· (월) 세무 집중화 교육</li>
                                            <li>· (수) 금융, 노무, 부동산, 법률교육</li>
                                            <li>· (목) 컨셉 및 상품교육</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <td>토요 특강</td>
                                    <td>법인영업사업단</td>
                                    <td class="tal">
                                        <ul>
                                            <li>· 신규 시장이슈와 영업기법에 대한 지속적 교육</li>
                                            <li>· 법인영업 Best Practice – FA 및 전문가 강좌</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"><strong>신입</strong> 보수과정</th>
                                    <td>4·7 차월<br>교육과정 (1박2일 진행)</td>
                                    <td>입사 1년 미만 법인 신입FA</td>
                                    <td class="tal">
                                        <ul>
                                            <li>· 세무 – 임원보수와 세금, 퇴직금 마련 플랜</li>
                                            <li>· 세무 – 자사주, 퇴직금 중간정산</li>
                                            <li>· 세무 – 세무조정계산서와 재무제표의 이해</li>
                                            <li>· 상법 – 정관의 이해</li>
                                            <li>· 상품솔루션 활용 Skill</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"><strong>1박2일</strong> 체계화과정</th>
                                    <td>격월진행</td>
                                    <td>법인FA대상</td>
                                    <td class="tal">
                                        <ul>
                                            <li>· 세무조정 계산서 이해</li>
                                            <li>· 세금포트폴리오 & 회계의 기초</li>
                                            <li>· 정관분석</li>
                                            <li>· 크레탑&재무제표 분석</li>
                                            <li>· 세금체계화 가업승계 과정</li>
                                        </ul>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"><strong>1박2일</strong> 심화과정</th>
                                    <td>1박2일 심화과정</td>
                                    <td>법인FA대상 반기1회</td>
                                    <td class="tal">
                                        <ul>
                                            <li>· 상법 – 경영권방어와 지분구조</li>
                                            <li>· 상품 – 新 시장 재물보험, 배상책임보험 컨설팅</li>
                                            <li>· 세무 – 재무구조개선과 신용도 제고</li>
                                            <li>· 세무 – 가업승계와 세대이전 플랜</li>
                                            <li>· 노무 – 노무컨설팅</li>
                                            <li>· 사례 – 新 마케팅 솔루션</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="img_wrap" style="padding: 20px; background-color: #fff;">
                            <img src="/img/recruit/fa/img-cont2.gif" alt="" class="img-cont"/>
                        </div>
                    </div>
                </li>
                <li class="item_edu">
                    <div class="info">
                        <p class="tit">개인영업교육</p>
                        <p class="desc">분기마다 수도권과 지방을 구분하여 각기 진행되는 1박2일 심화 과정은 매주 쏟아지는<br> 교육과정을 체계적으로 다시 복습하고 정리하는 기회입니다. <br>매회 정원이 초과되는 몰입도 심화 과정은 선착순으로 진행됩니다.</p>
                        <button class="btn_info" type="button"><span>교육과정 상세</span><img src="" data-images-path="/image/svg/plus01.svg" alt=""></button>
                    </div>
                    <div class="box_thumb">
                        <img src="/img/recruit/fa/edu_thumb2.jpg" alt="교육현장">
                    </div>

                    <div class="group_detail">
                        <table class="table table-bordered">
                            <caption></caption>
                            <colgroup>
                                <col>
                            </colgroup>
                            <thead>
                            <tr>
                                <th scope="col">교육과정</th>
                                <th scope="col">구분</th>
                                <th scope="col">교육대상</th>
                                <th scope="col">교육내용</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row"><strong>신입</strong>교육</th>
                                <td>신입교육 과정</td>
                                <td>개인영업 신입 FA</td>
                                <td class="tal">
                                    <ul>
                                        <li>· 경력신입, 무 경력신입 과정</li>
                                        <li>· 입사 3개월 차 교육과정</li>
                                        <li>· 입사 13개월 차 교육과정</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row"><strong>전문가</strong> 과정</th>
                                <td>전문가 과정</td>
                                <td>개인영업사업단</td>
                                <td class="tal">
                                    <ul>
                                        <li>· 생·손보 마스터과정</li>
                                        <li>· DB컨설팅 마스터과정</li>
                                        <li>· 법인컨설팅 심화과정</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <th><strong>커리어강화</strong> 과정</th>
                                <td>커리어 강화과정</td>
                                <td>개인영업사업단</td>
                                <td class="tal">
                                    <ul>
                                        <li>· 육성실장 역량 강화과정</li>
                                        <li>· 지점장 양성과정</li>
                                        <li>· 사업단장 역량 강화과정</li>
                                    </ul>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <img src="/img/recruit/fa/img-cont4.gif" alt="" class="img-cont">
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <!-- //교육지원 -->

    <!-- 광고홍보 -->
    <section id="promote" class="section">
        <div class="inner clearfix">
            <h3 class="tit_section"><em>02</em><span>광고·홍보 지원</span></h3>
            <ul class="list_promote">
                <li class="item_promote">
                    <p class="tit">유명 배우들 광고모델 발탁</p>
                    <p class="desc">피플라이프는 보다 전문적이고 세련된 기업브랜드를 구축하고자 유명 배우를 광고모델로<br> 대대적인 광고를 시행하였습니다.<br> 이후 주요 일간지를 통한 지속적인 광고마케팅으로 기업 신뢰도 향상에 힘을 기울이고<br> 있습니다.</p>
                </li>
                <li class="item_promote">
                    <p class="tit">Cable, 종편, 홈쇼핑을 넘어 공중파로</p>
                    <p class="desc">2016년 10월 Cable TV와 종편을 통한 인포머셜 광고를 시작으로<br> 공중파 방송까지 피플라이프와 보험클리닉 브랜드 광고를 지속하여 확대할 예정으로,<br> 보험상품을 비교하고 검토하여 가입하려는 모든 고객이 우선 찾는 GA 브랜드가<br> 되고자 합니다. </p>
                </li>
                <li class="item_promote">
                    <p class="tit">다양한 온라인 마케팅 및 신문광고 진행</p>
                    <p class="desc">네이버, 다음, 구글, SNS, 유튜브 등의 대표 온라인 매체와 신문광고를 통한 전방위<br> 마케팅으로 컨설턴트의 시장개척을 지원합니다.</p>
                </li>
            </ul>
            <button class="videoLayer" data-src="/video/tvcf/bohumclinic/2020_season2_1.mp4" data-title="TVC 광고 <나이스>편" data-type="" type="button" title="영상재생">
                <img class="thumb" src="/img/recruit/fa/promote_player_bg.jpg" alt="스크린샷">
            </button>
        </div>
    </section>
    <!-- //광고홍보 -->

    <!-- 상품제휴 -->
    <section id="alliance" class="section">
        <div class="inner">
            <h3 class="tit_section"><em>03</em><span>상품제휴</span></h3>
            <div class="list_alliance life">
                <p class="tit_list"><strong>생명보험</strong>국내 주요 생명보험사와 상품판매 업무제휴를 맺어 고객중심 맞품형 상품을 제안합니다.</p>
                <ul>
                    <li><span><img src="" alt="로고"></span></li>
                </ul>
            </div>
            <div class="list_alliance damage">
                <p class="tit_list"><strong>손해보험</strong>국내 주요 손해보험사와 상품판매 업무제휴를 맺어 화재보험 전문가를 통한 종합관리 및 손해보험사 상품 비교를 통해 효율적인 방법을 제안합니다.</p>
                <ul>
                    <li><span><img src="" alt="로고"></span></li>
                </ul>
            </div>
        </div>
    </section>
    <!-- //상품제휴 -->

    <!-- 전문가 네트워크 -->
    <section id="network" class="section">
        <div class="inner clearfix">
            <h3 class="tit_section"><em>04</em><span>전문가 네트워크</span></h3>
            <div class="info">
                <p class="desc">기업의 경영과 소유 과정에서 발생되는 문제의 해결을 위해서는<br> 각 분야에 대해 가장 전문적인 이론과 실전 경험을 갖춘 인력이<br> 필요합니다.<br> 피플라이프는 중소기업 경영지원컨설팅 분야에서 뛰어난 솔루션을<br> 보유한 전문가 그룹과 제휴관계를 맺고 있습니다.</p>
                <a class="btn_info" href="/intro/Network" target="_blank"><span>자세히 보기</span><img src="" data-images-path="/image/svg/plus01.svg" alt=""></a>
            </div>
            <div class="box_thumb">
                <img src="/img/recruit/fa/network_thumb.jpg" alt="남성들">
            </div>
        </div>
    </section>
    <!-- //전문가 네트워크 -->

    <!-- 고객서비스 -->
    <section id="seminar" class="section">
        <div class="inner clearfix">
            <h3 class="tit_section"><em>05</em><span>고객세미나 지원</span></h3>
            <div class="info">
                <p class="tit">국내 최대규모 아마추어 골프대회,<br>피플라이프 중소기업 CEO GOLF CLASSIC </p>
                <p class="desc">대규모 ‘피플라이프 중소기업 CEO Golf Classic’ 을 위해<br> 전국에 다수의 골프장과 제휴하는 등 VIP 세미나를 위한<br> 인프라를 구축하고 있습니다.</p>
            </div>
            <div class="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"><img src="/img/recruit/fa/seminar_thumb1.jpg" alt="단체사진"></div>
                    <div class="swiper-slide"><img src="/img/recruit/fa/seminar_thumb2.jpg" alt="단체사진"></div>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
            </div>
        </div>
    </section>
    <!-- //고객서비스 -->

    <!-- 전산시스템 -->
    <section id="computer" class="section">
        <div class="inner clearfix">
            <h3 class="tit_section"><em>06</em><span>전산 시스템</span></h3>
            <div class="info">
                <p class="tit">PC, MOBILE 통합 고객관리 시스템 지원</p>
                <p class="desc">자체 개발한 ERP시스템 PINES를 통해 PC, MOBILE에서 계약관리,<br> 고객관리, 성과 및 소득관리를 통합적으로 관리할 수 있는 편의가<br> 제공되며 지능형보장분석시스템으로<br>경쟁력을 지원합니다.</p>
            </div>
            <div class="box_thumb">
                <img src="/img/recruit/fa/computer_thumb.jpg" alt="노트북">
            </div>
        </div>
    </section>
    <!-- //전산시스템 -->

    <!-- DB제공 -->
    <section id="system" class="section">
        <div class="inner clearfix">
            <h3 class="tit_section"><em>07</em><span>DB제공 시스템</span></h3>
            <div class="info">
                <p class="desc">유명 배우 광고모델 진행, Cable, 종편, 홈쇼핑,<br> 공중파 방송진행과 골프대회, 고객초청 세미나를 비롯한 VIP 마케팅<br> 및 다양한 온라인 마케팅과 신문광고를 통해 고객들이 자발적으로<br> 찾아오는 양질의 시장을 제공함으로써 컨설턴트의 활동을<br> 지원합니다.</p>
                <div class="list_thumb">
                    <img src="/img/recruit/fa/system_thumb2.jpg" alt="신문">
                    <img src="/img/recruit/fa/system_thumb3.jpg" alt="시험">
                </div>
            </div>
            <div class="box_thumb">
                <img src="/img/recruit/fa/system_thumb1.jpg" alt="노트북">
            </div>
        </div>
    </section>
    <!-- //DB제공 -->

    <!-- GA빅뱅 -->
    <section id="ga" class="section">
        <div class="inner">
            <h3 class="tit_section">피플라이프가 주도하는 GA 빅뱅 </h3>
            <p class="desc_section">보험 · 금융 전문가의 길, 피플라이프가 제시해드립니다.</p>
            <ul class="list_ga">
                <li class="item_ga">
                    <p class="pos">FA</p>
                    <p class="tit">보험 영업에 관심있는 자</p>
                    <p class="desc">다양한 보험·금융지식을 기반으로<br> Life Plan을 제시하는 전문가</p>
                </li>
                <li class="item_ga">
                    <p class="pos">지점장</p>
                    <p class="tit">보험경력 1년 이상</p>
                    <p class="desc">Playing Coach로서 산하 FA<br> 활동지원 및 신인FA육성</p>
                </li>
                <li class="item_ga">
                    <p class="pos">사업단장</p>
                    <p class="tit">보험경력 2년 이상</p>
                    <p class="desc">사업단 산하 조식<br> Management&Motivation </p>
                </li>
                <li class="item_ga">
                    <p class="pos">영업<br>본부장</p>
                    <p class="tit">보험경력 3년 이상</p>
                    <p class="desc">본부 산하 사업단 Management<br> (최소 3개 사업단, 70명 이상) </p>
                </li>
            </ul>
            <dl class="list_benefit">
                <dt class="tit">공통 자격조건: 생명보험, 손해보험 및 변액자격증 소지자</dt>
                <dd class="desc">초기 정착자금 지원</dd>
                <dd class="desc">조직구축 정착자금 지원</dd>
                <dd class="desc">조직구축 정착자금 및<br>본부 운영비 지원</dd>
            </dl>
        </div>
    </section>
    <!-- //GA빅뱅 -->

    <!-- 방문상담신청 -->
    <section id="applyForm"  class="section">
        <div class="inner">
            <h3 class="tit_section"><mark>FA 지원하기</mark></h3>
            <!-- <li class="row row_channel row_css" data-check-pass="false" data-inbound-prop="channel" data-inbound-value="EFA상담매니저" data-check-comment="지원부문을 선택하세요">
                 <div class="select_wrap">
                    <select class="select_css">
                        <option value="EFA상담매니저">EFA 상담매니저</option>
                        <option value="법인상담매니저">법인 상담매니저</option>
                        <option value="개인영업">개인영업</option>
                    </select>
                    <u class="select_arrow arrow_css"></u>
                </div>
            </li> -->
            <li class="row row_carrer_life row_css" data-check="false" data-check-pass="true" data-inbound-prop="lifeCareerYn" data-inbound-value="Y" data-check-comment="생보경력유무를 선택하세요">
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_life btnCarrerLife">
                    <input name="carrer_life" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_carrer_damage row_css" data-check="false" data-check-pass="true" data-inbound-prop="damageCareerYn" data-inbound-value="Y" data-check-comment="손보경력유무를 선택하세요">
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="Y" type="radio" data-auto-focus="false" tabindex="-1" checked>
                    <em class="theme_border_color"><!--있음--></em>
                </label>
                <label class="btn_carrer_damage btnCarrerDamage">
                    <input name="carrer_damage" value="N" type="radio" data-auto-focus="false" tabindex="-1">
                    <em class="theme_border_color"><!--없음--></em>
                </label>
            </li>
            <li class="row row_file row_css" data-check="false" data-check-pass="true" data-inbound-prop="fileAttachId" data-inbound-value="" data-check-comment="입사지원서를 업로드하세요">
                <button type="button"></button>
                <span class="file_name"></span>
                <input type="file" class="hidden">
            </li>
            <div class="render_form"></div>
        </div>
    </section>
    <!-- //방문상담신청 -->

    <!-- 채용문의 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="txt1">보험영업, 한 단계 도약을 원하신다면?</p>
            <h3 class="txt2">피플라이프와 함께하세요!<br><strong>당신이 그 기회의 주인공 입니다.</strong></h3>
            <em class="txt3">※ 본 화면은 보험설계사를 모집하는 것으로 정규 또는 비정규 직원채용과 무관합니다.</em>
            <div class="box_about">
                <p class="tit">채용문의</p>
                <p class="tel">02-2162-7753</p>
                <p class="cmt">자세한 사항은 인사담당자가 안내 드리겠습니다.</p>
            </div>
        </div>
    </section>
    <!-- //채용문의 -->

    <!-- 입사문의 바로가기 floating -->
    <aside id="banner_apply">
        <div class="inner">
            <button class="btn_apply anchor" data-target="#applyForm" type="button">
                <span class="screen_out">입사문의 바로가기</span>
            </button>
        </div>
    </aside>
    <!-- //입사문의 바로가기 floating -->
</main>
<!-- /main -->
</template>

<style scoped lang="scss">
    @import '@/assets/scss/recruit/recruitfa.scss';
</style>

<script>
    export default {}
</script>