<template>
<!-- main -->
<main id="container">

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <div class="txt">
                <em>FA</em>
                <strong>한층 풍요로운<br> 라이프 스타일을 위한<br> 보험 전문 컨설팅, FA </strong>
            </div>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 주요정보 -->
    <section id="information" class="section">
        <div class="inner clearfix">
            <h3 class="tit_section"><img :src="imgPath+'information_title.png'" alt="FA"></h3>
            <div class="thumb">
                <img :src="imgPath+'information_thumb.jpg'" alt="사진">
            </div>
            <div class="info">
                <p class="txt-top">안정적인 자산관리 테라피<br><strong>「 FA 」</strong></p>
                <p class="txt-bottom">보험을 통한 보장이라는 안전장치를 기반으로,<br> 체계적 종합자산관리를 통한 인생 전반을 설계하고 있습니다.<br><br> 합리적 금융소비 활동을 위한 최적화된 디지털 알고리즘을 제공하며, <br> 심층 평가와 분석 기반의 맞춤 컨설팅을 통해 <br> 고객 자산관리 테라피를 제안합니다.</p>
                <a class="link_more" href="#freeConsult"><span>개인영업(FA) 상담신청</span><img src="" data-images-path="/image/svg/arr_right01.svg"></a>
            </div>
        </div>
    </section>
    <!-- //주요정보 -->

    <!-- 서비스 -->
    <section id="service" class="section">
        <div class="inner">
            <h3 class="tit_section">피플라이프 FA 차별화 서비스</h3>
            <ul class="list_service">
                <li class="item_service">
                    <div class="thumb">
                        <p class="tit">고객 맞춤형<br> 방문 서비스<br> 제공 </p>
                        <img :src="imgPath+'service_thumb1.jpg'" alt="남성">
                    </div>
                    <div class="info">
                        <p class="num">01</p>
                        <p class="desc">365일 언제 어디서나!<br> 고객님이 요청한 시간, 장소로 FA가 직접 방문하여 1:1 맞춤형 컨설팅을 진행합니다.<br> 고객님의 얘기를 직접 듣고 최적의 솔루션을 제공합니다. </p>
                    </div>
                </li>
                <li class="item_service">
                    <div class="thumb">
                        <p class="tit">무료 상담<br> 서비스</p>
                        <img :src="imgPath+'service_thumb2.jpg'" alt="남성">
                    </div>
                    <div class="info">
                        <p class="num">02</p>
                        <p class="desc">기존 보험 점검, 리모델링을 위한 상담 비용 ZERO!<br>전문가가 보험점검부터 관리까지 무료로 전담하며<br> 상담이 완료된 후 맞춤보장분석 보고서를 무료로 제공합니다.</p>
                    </div>
                </li>
                <li class="item_service">
                    <div class="thumb">
                        <p class="tit">국내 주요<br> 보험사</p>
                        <img :src="imgPath+'service_thumb3.jpg'" alt="남성">
                    </div>
                    <div class="info">
                        <p class="num">03</p>
                        <p class="desc">피플라이프는 국내 주요 보험사의 상품 포트폴리오를<br> 실시간 비교 분석하여 고객에게 유리한 상품을 추천합니다.</p>
                    </div>
                </li>
                <li class="item_service">
                    <div class="thumb">
                        <p class="tit">업계 전문가 그룹</p>
                        <img :src="imgPath+'service_thumb4.jpg'" alt="남성">
                    </div>
                    <div class="info">
                        <p class="num">04</p>
                        <p class="desc">단순한 보험 비교를 넘어 이론과 실전 경험을 갖춘 <br>전문가 네트워크를 통해 생애 전반의 라이프 케어 및 <br> 자산관리컨설팅 등 고객님의 풍요로운 미래를 위해 노력합니다.</p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
    <!-- 서비스 -->

    <!-- 전문가 네트워크 -->
    <networkTab />
    <!-- //전문가 네트워크 -->

     <!-- 디지털 알고리즘 -->
    <section id="algorithm" class="section">
        <div class="inner">
            <h3 class="tit_section">최적화된 보험상품판매를 위한<br>디지털 알고리즘</h3>
            <ul class="list_graph left">
               <li class="item_graph"><p><span>내 보험 조회 및<br> 자가진단 서비스</span></p></li>
               <li class="item_graph"><p><span>보험금<br> 청구업무 지원</span></p></li>
               <li class="item_graph"><p><span>FA 지정<br> 등록 및 문의</span></p></li>
               <li class="item_graph"><p><span>연금 및<br> 건강검진결과 조회</span></p></li>
            </ul>
            <ul class="list_graph right">
                <li class="item_graph"><p><span>보험상품 비교<br>확인서, 마이언더<br>라이팅</span></p></li>
                <li class="item_graph"><p><span>스마트명함,<br>미청구보험금<br>조회</span></p></li>
                <li class="item_graph"><p><span>프로보장분석,<br>한장 보험료<br>비교</span></p></li>
                <li class="item_graph"><p><span>생보 환급률<br>비교,<br>약관조회</span></p></li>
            </ul>
            <img class="phone" :src="imgPath+'algorithm_phone.png'" alt="스마트폰">
            <img class="model" :src="imgPath+'algorithm_model.png'" alt="모델 현빈">
        </div>
    </section>
    <!-- //디지털 알고리즘 -->

    <!-- 인포머셜 광고 -->
    <!-- <videoSwiper02 /> -->
    <!-- //인포머셜 광고 -->

    <!-- 업계 no.1 피플라이프 보험컨설팅 -->
    <bohumConsulting />
    <!-- //업계 no.1 피플라이프 보험컨설팅 -->

    <!-- 컨설팅 프로세스 -->
    <processSwiper02 />
    <!-- //컨설팅 프로세스 -->

    <!-- 상담신청  -->
    <section id="freeConsult" class="section">
        <div class="inner">
            <h3 class="tit_section">보험전문가가 찾아오는 보험 컨설팅 신청하기</h3>
            <ul class="list_about">
                <li class="item_about">국내 주요 보험사<br> 상품 비교 </li>
                <li class="item_about">마이매니저로 병의원 진료정보를<br>통해 간편보험 가입조건 비교</li>
                <li class="item_about">보험 점검부터 관리까지<br> 모든 서비스 무료제공 </li>
            </ul>
            <div class="render_form"></div>
            <!-- <li class="row row_talktime row_css clearfix" data-type="talkTime" data-check-pass="true" data-check-comment="연락 가능한 시간을 선택해주세요" data-inbound-prop="talk-time">
                <label class="select_wrap">
                    <select name="consult" class="openSans consult select_css">
                        <option value="언제나 통화가능" selected>언제나 통화가능</option>
                        <option value="오전 09:00 ~ 10:00">오전 09:00 ~ 10:00</option>
                        <option value="오전 10:00 ~ 11:00">오전 10:00 ~ 11:00</option>
                        <option value="오전 11:00 ~ 12:00">오전 11:00 ~ 12:00</option>
                        <option value="오후 01:00 ~ 02:00">오후 01:00 ~ 02:00</option>
                        <option value="오후 02:00 ~ 03:00">오후 02:00 ~ 03:00</option>
                        <option value="오후 03:00 ~ 04:00">오후 03:00 ~ 04:00</option>
                        <option value="오후 04:00 ~ 05:00">오후 04:00 ~ 05:00</option>
                        <option value="오후 05:00 ~ 06:00">오후 05:00 ~ 06:00</option>
                    </select>
                    <u class="select_arrow arrow_css"></u>
                </label>
            </li> -->
        </div>
    </section>
    <!-- //상담신청  -->

    <!-- 안내사항 -->
    <section id="about" class="section">
        <div class="inner">
            <p class="desc_section">보험영업, 한 단계 도약을 원하신다면?</p>
            <h3 class="tit_section">피플라이프와 함께하세요!<br><strong>다음 성공의 주인공은 당신 입니다.</strong></h3>
            <a class="link_about" href="/recruit/RecruitFa"><span>개인영업(FA) 인재채용 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>
        </div>
    </section>
    <!-- //안내사항 -->
</main>
<!-- /main -->
</template>



<script>
export default {
    data() {
        return {
            imgPath: '/img/sales/fa/'
        }
    }
}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/sales/fa.scss';
</style>
