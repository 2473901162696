<template>
<!-- main -->
<main id="container">

    <!-- 메인비주얼 -->
    <section id="visual" class="section">
        <div class="inner">
            <div class="txt">
                <em>Multi</em>
                <strong>단순한 보험 판매를 넘어<br> 생애 전반의 라이프 케어<br> 전문 컨설팅</strong>
            </div>
        </div>
    </section>
    <!-- //메인비주얼 -->

    <!-- 주요정보 -->
    <section id="information" class="section">
        <div class="inner">
            <h3 class="tit_section"><img src="/img/sales/efa/information_title.png" alt="EFA"></h3>
            <div class="thumb">
                <img src="/img/sales/efa/information_thumb.jpg" alt="사진">
            </div>
            <div class="info">
                <p class="txt-top">찾아가는 보험클리닉<br><strong>「 Multi 」</strong></p>
                <p class="txt-bottom">
                    피플라이프만의 철저한 교육 시스템으로 무장한 Multi 채널은 <br>
                    정규직 찾아가는 보험클리닉 EFA와 외근형 찾아가는 보험클리닉 MFA로 구성되어 있습니다.    
                    <br>
                    <br>
                    교육을 받은 전문가가 고객님의 고민을 듣고<br>
                    보험을 문진하고 처방하는 종합 컨설팅 서비스를 제공합니다.
                </p>
            </div>
        </div>
    </section>
    <!-- //주요정보 -->

    <!-- 비교배너 -->
    <section id="compare" class="section">
        <h3 class="tit_section">비정규직 영업 <span style="color: #008ed4; font-size: 0.8em;">VS</span> 정규직 EFA</h3>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide">
                    <div class="left" style="background-image:url('/img/sales/efa/compare_thumb1_left.jpg')">
                        <div class="box" style="color:#fff">
                            <p class="tit">비정규직 영업</p>
                            <p class="desc"><mark>보험전문 컨설팅</mark></p>
                        </div>
                    </div>
                    <div class="right"  style="background-image:url('/img/sales/efa/compare_thumb1_right.jpg')">
                        <div class="box" style="color:#000">
                            <p class="tit">정규직 EFA</p>
                            <p class="desc"><mark>생애 전반의 라이프 케어 컨설팅 </mark></p>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="left"  style="background-image:url('/img/sales/efa/compare_thumb2_left.jpg')">
                        <div class="box" style="color:#fff">
                            <p class="tit">비정규직 영업</p>
                            <p class="desc"><mark>시장개발 지인 영업의 한계 </mark></p>
                            <p class="desc"><mark>부담스런 DB 구입비용</mark></p>
                        </div>
                    </div>
                    <div class="right"  style="background-image:url('/img/sales/efa/compare_thumb2_right.jpg')">
                        <div class="box" style="color:#fff">
                            <p class="tit">정규직 EFA</p>
                            <p class="desc"><mark>안정적인 DB 제공</mark></p>
                        </div>
                    </div>
                </div>
                <div class="swiper-slide">
                    <div class="left"  style="background-image:url('/img/sales/efa/compare_thumb3_left.jpg')">
                        <div class="box" style="color:#fff">
                            <p class="tit">비정규직 영업</p>
                            <p class="desc"><mark>스트레스+동기부여Zero</mark></p>
                            <p class="desc"><mark>저조한 실적</mark></p>
                        </div>
                    </div>
                    <div class="right"  style="background-image:url('/img/sales/efa/compare_thumb3_right.jpg')">
                        <div class="box" style="color:#000">
                            <p class="tit">정규직 EFA</p>
                            <p class="desc"><mark>인센티브+프로모션</mark></p>
                            <p class="desc"><mark>고소득 실현</mark></p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
        </div>
    </section>
    <!-- //비교배너 -->

    <!-- 인재지원 -->
    <section id="people" class="section">
        <div class="inner">
            <h3 class="tit_section">왜, 피플라이프 정규직 보험상담매니저는 다를까요?</h3>
            <ul class="list_people">
                <li class="item_people">
                    <p class="tit">편리함</p>
                    <p class="tag">#언제 #어디서나 #쉽게</p>
                    <p class="desc"> 고객님이 요청한 시간, 장소로 직접 방문하여<br> 1:1 맞춤 컨설팅을 진행합니다</p>
                </li>
                <li class="item_people">
                    <p class="tit">편안함</p>
                    <p class="tag">#부담없는 #보험컨설팅</p>
                    <p class="desc">EFA 정규직 보험상담매니저는 월 고정 급여를 받는<br> 본사 소속 상담사로 보험 가입을 권유하지 않습니다.</p>
                </li>
                <li class="item_people">
                    <p class="tit">객관성</p>
                    <p class="tag">#고객만족 #최우선</p>
                    <p class="desc">EFA 상담매니저는 판매 수당에 대한 부담이 없어, <br> 객관적으로 고객님께 가장 유리한 상품만을 추천합니다. </p>
                </li>
            </ul>
        </div>
        <div class="bg_circle"></div>
    </section>
    <!-- //인재지원 -->

    <!-- 차별화 서비스 -->
    <section id="special" class="section">
        <div class="inner">
            <h3 class="tit_section">피플라이프 EFA 차별화 서비스</h3>
            <div class="swiper-container swiper-top">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="thumb">
                            <img src="/img/sales/efa/special_thumb1.jpg" alt="사진">
                        </div>
                        <p class="tit">고객 맞춤형 방문 서비스 제공 </p>
                        <p class="desc">365일 언제 어디서나!<br> 고객님이 요청한 시간, 장소로 FA가 직접 방문하여 1:1 맞춤형 컨설팅을 진행합니다.<br> 고객님의 얘기를 직접 듣고 최적의 솔루션을 제공합니다.</p>
                    </div>
                    <div class="swiper-slide">
                        <div class="thumb">
                            <img src="/img/sales/efa/special_thumb2.jpg" alt="사진">
                        </div>
                        <p class="tit">무료 상담 서비스 </p>
                        <p class="desc">기존 보험 점검, 리모델링을 위한 상담 비용 ZERO!<br>전문가가 보험점검부터 관리까지 무료로 전담하며<br> 상담이 완료된 후 맞춤보장분석 보고서를 무료로 제공합니다. </p>
                    </div>
                    <div class="swiper-slide">
                        <div class="thumb">
                            <img src="/img/sales/efa/special_thumb3.jpg" alt="사진">
                        </div>
                        <p class="tit">국내 주요 보험사 상품 비교 분석 </p>
                        <p class="desc">피플라이프는 국내 주요 보험사 제휴로<br>상품 포트폴리오를 실시간 비교 분석하여<br>고객에게 유리한 상품을 추천합니다. </p>
                    </div>
                    <div class="swiper-slide">
                        <div class="thumb">
                            <img src="/img/sales/efa/special_thumb4.jpg" alt="사진">
                        </div>
                        <p class="tit">업계 전문가 그룹 </p>
                        <p class="desc"> 단순한 보험 비교를 넘어 이론과 실전 경험을 갖춘<br>전문가 네트워크를 통해 생애 전반의 라이프 케어 및 자산관리컨설팅 등<br> 고객님의 풍요로운 미래를 위해 노력합니다. </p>
                    </div>
                </div>
            </div>

            <div class="swiper-container swiper-thumb">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" style="background-image:url('/img/sales/efa/special_thumb1.jpg')"><p class="tit">고객 맞춤형 방문<br>서비스 제공</p></div>
                    <div class="swiper-slide" style="background-image:url('/img/sales/efa/special_thumb2.jpg')"><p class="tit">무료 상담 서비스</p></div>
                    <div class="swiper-slide" style="background-image:url('/img/sales/efa/special_thumb3.jpg')"><p class="tit">국내 주요 보험사<br>상품 비교분석</p></div>
                    <div class="swiper-slide" style="background-image:url('/img/sales/efa/special_thumb4.jpg')"><p class="tit">업계 <br>전문가 그룹</p></div>
                </div>
            </div>

            <div class="swiper-nav">
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                <button class="swiper-button-prev swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_left01.svg" alt="뒤로"></button>
                <button class="swiper-button-next swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></button>
            </div>
        </div>
    </section>
    <!-- //차별화 서비스 -->

    <!-- 전문가 네트워크 -->
    <networkTab></networkTab>
    <!-- //전문가 네트워크 -->

    <!-- 2020년 수상내역 -->
    <award2020></award2020>    
    <!-- //2020년 수상내역 -->

    <!-- TV CF -->
    <!-- <section id="tvcf" class="section">
        <div class="inner">
            <p class="desc_section"><strong>PEOPLELIFE</strong> TV CF</p>
            <h3 class="tit_section">보험을 바로.잡다</h3>
            <p class="video_section"><strong>「</strong>나이스<strong>」</strong>편</p>
            <button class="videoLayer" data-src="/video/tvcf/bohumclinic/2020_season2_1.mp4" data-title="TVC 광고 <나이스>편" type="button" title="영상재생">
                <i class="ico_play"></i>
            </button>
            <div class="list_tvcf">
                <p class="tit_list">피플라이프 TV CF 보기</p>
                <button class="videoLayer video1" data-src="/video/tvcf/bohumclinic/2019_season2_1.mp4" data-title="TVC 광고 <어쩌다 마주친>편" type="button" title="영상재생">
                    <img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2019_season2_1.jpg" alt="">
                    <p class="tit">&#60;어쩌다 마주친&#62;편</p>
                </button>
                <button class="videoLayer video2" data-src="/video/tvcf/bohumclinic/2019_season2_2.mp4" data-title="TVC 광고 <여기서 내려요>편" type="button" title="영상재생">
                    <img class="thumb" src=""  data-images-path="/video/tvcf/bohumclinic/2019_season2_2.jpg"  alt="">
                    <p class="tit">&#60;여기서 내려요&#62;편</p>
                </button>
                <button class="videoLayer video3" data-src="/video/tvcf/bohumclinic/2020_season2_2.mp4" data-title="TVC 광고 <자꾸만 보고싶네>편" type="button" title="영상재생">
                    <img class="thumb" src="" data-images-path="/video/tvcf/bohumclinic/2020_season2_2.jpg" alt="">
                    <p class="tit">&#60;자꾸만 보고싶네&#62;편</p>
                </button>
            </div>
        </div>
    </section> -->
    <!-- //TV CF -->

    <!-- 업계 no.1 피플라이프 보험컨설팅 -->
    <bohumConsulting></bohumConsulting>
    <!-- //업계 no.1 피플라이프 보험컨설팅 -->

    <!-- 컨설팅 프로세스 -->
    <section id="process" class="section">
        <div class="inner">
            <h3 class="tit_section">고객 맞춤형 컨설팅 프로세스</h3>
            <div class="swiper-container swiper-top">
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">1</span>STEP</p>
                            <p class="tit">상담 신청</p>
                            <p class="desc">상담을 원하시는 고객님의 기본적인 정보를 등록해주세요.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">2</span>STEP</p>
                            <p class="tit">정규직 보험상담매니저(EFA) 지정</p>
                            <p class="desc">수 많은 경험과 실전 지식을 보유한<br>고객 맞춤 정규직 보험상담매니저(EFA)가 지정됩니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">3</span>STEP</p>
                            <p class="tit">고객상담(고객분석)</p>
                            <p class="desc">고객님이 현재 보유한 보험의 보장 및 보험료가 적정한지 확인합니다.<br> 투자 성향, 재무상황 및 미래의 계획을 상담하고 고객님의 보험 및 자산을 분석합니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">4</span>STEP</p>
                            <p class="tit">상품 비교 및 분석</p>
                            <p class="desc">피플라이프만의 지능형 보장분석시스템 마이매니저를 통해<br> 국내 주요 보험사 제휴로 상품을 비교분석 합니다.</p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">5</span>STEP</p>
                            <p class="tit">자산관리 컨설팅 지원</p>
                            <p class="desc">CFP팀이 고객님의 증권을 분석하고 금융연구소에서 보험 비교를 넘어 생애 전반의<br>라이프 케어 및 자산관리 컨설팅을 지원합니다. </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">6</span>STEP</p>
                            <p class="tit">맞춤 포트폴리오 제안</p>
                            <p class="desc">차별화된 상품 포트폴리오로 ‘보험사’가 아닌 고객님에게 유리한 상품만을<br> 추천한 맞춤 포트폴리오를 제안합니다. </p>
                        </div>
                    </div>
                    <div class="swiper-slide">
                        <div class="text-area">
                            <p class="step"><span class="num">7</span>STEP</p>
                            <p class="tit">상담 후 평생관리</p>
                            <p class="desc">보험금 청구 시스템 및 지속 관리를 통해 고객 편의성을 증대시키고,<br>정기적인 검진을 통해 고객 만족을 위해 노력합니다.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="swiper-container swiper-thumb">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" style="background-image:url('/img/common/contents/ProcessSwiper/fa/thumb1_1.jpg')"></div>
                    <div class="swiper-slide" style="background-image:url('/img/common/contents/ProcessSwiper/fa/thumb2_1.jpg')"></div>
                    <div class="swiper-slide" style="background-image:url('/img/common/contents/ProcessSwiper/fa/thumb3_1.jpg')"></div>
                    <div class="swiper-slide" style="background-image:url('/img/common/contents/ProcessSwiper/fa/thumb4_1.jpg')"></div>
                    <div class="swiper-slide" style="background-image:url('/img/common/contents/ProcessSwiper/fa/thumb5_1.jpg')"></div>
                    <div class="swiper-slide" style="background-image:url('/img/common/contents/ProcessSwiper/fa/thumb6_1.jpg')"></div>
                    <div class="swiper-slide" style="background-image:url('/img/common/contents/ProcessSwiper/fa/thumb7_1.jpg')"></div>
                </div>
            </div>

            <div class="swiper-nav">
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Navigation -->
                <button class="swiper-button-prev swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_left01.svg" alt="뒤로"></button>
                <button class="swiper-button-next swiper-button" type="button"><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></button>
            </div>
        </div>
    </section>
    <!-- //컨설팅 프로세스 -->

    <!-- 상담신청  -->
    <section id="freeConsult" class="section">
        <div class="inner">
            <h3 class="tit_section">보험전문가가 찾아오는 보험 컨설팅 신청하기</h3>
            <ul class="list_about">
                <li class="item_about">국내 주요 보험사<br> 상품 비교 </li>
                <li class="item_about">마이매니저로 병의원 진료정보를<br>통해 간편보험 가입조건 비교</li>
                <li class="item_about">보험 점검부터 관리까지<br> 모든 서비스 무료제공 </li>
            </ul>
            <div class="render_form"></div>
            <li class="row row_talktime row_css clearfix" data-type="talkTime" data-check-pass="true" data-check-comment="연락 가능한 시간을 선택해주세요" data-inbound-prop="talk-time">
                <label class="select_wrap">
                    <select name="consult" class="openSans consult select_css">
                        <option value="언제나 통화가능" selected>언제나 통화가능</option>
                        <option value="오전 09:00 ~ 10:00">오전 09:00 ~ 10:00</option>
                        <option value="오전 10:00 ~ 11:00">오전 10:00 ~ 11:00</option>
                        <option value="오전 11:00 ~ 12:00">오전 11:00 ~ 12:00</option>
                        <option value="오후 01:00 ~ 02:00">오후 01:00 ~ 02:00</option>
                        <option value="오후 02:00 ~ 03:00">오후 02:00 ~ 03:00</option>
                        <option value="오후 03:00 ~ 04:00">오후 03:00 ~ 04:00</option>
                        <option value="오후 04:00 ~ 05:00">오후 04:00 ~ 05:00</option>
                        <option value="오후 05:00 ~ 06:00">오후 05:00 ~ 06:00</option>
                    </select>
                    <u class="select_arrow arrow_css"></u>
                </label>
            </li>
        </div>
    </section>
    <!-- //상담신청  -->

    <!-- 안내사항 -->
    <!-- <section id="about" class="section">
        <div class="inner">
            <p class="desc_section">고용을 넘어 안정적인 소득을 원하신다면? </p>
            <h3 class="tit_section">정규직 보험상담매니저에 도전하세요!<br><strong>당신이 그 기회의 주인공 입니다.</strong></h3>
            <a class="link_about" href="/recruit/RecruitEfa"><span>정규직 보험클리닉(EFA) 인재채용 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg" alt="앞으로"></a>
            <img class="bg" src="/img/sales/efa/about_bg.png" alt="">
            <img class="model" src="/img/sales/efa/about_model.png" alt="모델">
        </div>
    </section> -->
    <!-- //안내사항 -->
</main>
<!-- /main -->
</template>



<script>
    export default {}
</script>



<style lang="scss" scoped>
    @import '@/assets/scss/sales/efa.scss';
</style>
