<template>
  <!-- main -->
  <main id="container">
	<!-- 바로가기 -->
	<nav id="nav_container">
		<div class="inner">
			<div class="list_nav">
			<a class="link_nav anchor" data-target="#visual">메인</a>
			<a class="link_nav anchor" data-target="#business">
				사업단<span class="hide"> 현황</span>
			</a>
			<a class="link_nav anchor" data-target="#network">
				전문가<span class="hide"> 네트워크</span>
			</a>
			<a class="link_nav anchor" data-target="#roadMap">
				로드맵
			</a>
			<a class="link_nav anchor" data-target="#solution">
				<span class="hide">리스크 </span>솔루션
			</a>
			<!-- <a class="link_nav anchor" data-target="#worry">
				<span class="hide">27가지 </span>고민해결
			</a> -->
			<a class="link_nav anchor" data-target="#start">시작</a>
			<a class="link_nav anchor" data-target="#vision">
				<span class="hide">성공의 </span>비전제시
			</a>
			<a class="link_nav anchor" data-target="#ci">
				CI<span class="hide"> 소개</span>
			</a>
			</div>
		</div>
	</nav>
	<!-- //바로가기 -->

	<!-- 메인 비주얼 -->
	<section id="visual" class="section dataTarget" data-scroll-cut-line="10">
		<div class="inner">
			<h3 class="tit_section">
				지속가능경영을 위한<strong>리스크 관리 컨설팅 CEO클리닉</strong>
			</h3>
		</div>
	</section>
	<!-- //메인 비주얼 -->


	<section id="isCeo">
		<div class="inner">
			<h3 class="tit_section"><strong>CEO클리닉</strong>은,</h3>
			<p> 법인컨설팅의 명가 피플라이프의 <strong>중소ㆍ중견기업을 위한 경영효율화 전문 브랜드</strong>로써<br>
				기업과 오너CEO의 RISK관리, 가업승계, 증여와 상속에 이르기까지<br>
				세무, 법무, 노무, M&A, 부동산 등 특화된 전문가 네트워크와 협업하여<br>
				<strong>지속가능경영을 위한 가장 효율적인 솔루션 제공</strong>과 실행을 제안합니다.
			</p>
			<div class="label"><span>특히 기업과 주주, 임원들의 동반 성장과 안정을 위한 최적의 전략을 통해 명문가 사업 및 가업을 육성 합니다.</span></div>
		</div>
	</section>

	
	<!-- 사업단 -->
	<section id="business" class="section dataTarget" data-scroll-cut-line="10">
		<div class="inner">
			<h3 class="tit_section">
				전국 법인컨설팅 솔루션 <strong>「CEO CLINC」</strong>
			</h3>
			<div class="left">
				<p class="txt">
					피플라이프의 대표 법인컨설팅 브랜드 'CEO클리닉'은<br>
					세무사, 변호사, 노무사, 변리사, 부동산전문가 등 제휴된 여러 전문가 네트워크와 함께<br>
					<mark>'중소기업 경영효율화 법인컨설팅'</mark> 이라는 새로운 분야를 개척함으로써<br>
					가장 합리적인 전략과 솔루션을 제시하고<br>
					각종 경영 리스크관리 및 경영 효율화 전략을 실행 제안합니다.
				</p>
				<ul class="list_count">
					<!-- <li class="item_count">
						<p class="tit">CEO클리닉<strong>사업단 현황</strong></p>
						<p class="num"><span>89</span>개</p>
						<p class="source">2024년 5월 사업단 현황</p>
					</li> -->
					<!-- <li class="item_count">
						<p class="tit">CEO클리닉<strong>재무보좌관 현황</strong></p>
						<p class="num"><span>1,460</span>명</p>
						<p class="source">2022년 6월 설계사수 현황</p>
					</li> -->
				</ul>
			</div>
			<div class="right">
				<div class="map">
				<dl class="list_map seoul pos_left">
					<dt>
						<em>서울</em>
						<!-- <strong>46</strong> -->
					</dt>
				</dl>
				<dl class="list_map kangwon pos_right">
					<dt>
						<em>강원</em>
						<!-- <strong>4</strong> -->
					</dt>
					<!-- <dd><em>원주</em><strong>2</strong></dd> -->
					<!-- <dd><em>춘천</em><strong>1</strong></dd> -->
				</dl>
				<dl class="list_map gyeonggi pos_right">
					<dt>
						<em>경기</em>
						<!-- <strong>11</strong> -->
					</dt>
					<!-- <dd><em>인천</em><strong>1</strong></dd> -->
					<!-- <dd><em>일산</em><strong>1</strong></dd> -->
					<!-- <dd><em>성남</em><strong>1</strong></dd> -->
					<!-- <dd><em>안양</em><strong>1</strong></dd> -->
					<!-- <dd><em>용인</em><strong>4</strong></dd> -->
				</dl>

				<dl class="list_map chungchung pos_left">
					<dt>
						<em>충청</em>
						<!-- <strong>4</strong> -->
					</dt>
					<!-- <dd><em>충주</em><strong>1</strong></dd> -->
					<!-- <dd><em>제천</em><strong>1</strong></dd> -->
					<!-- <dd><em>대전</em><strong>3</strong></dd> -->
				</dl>
				<dl class="list_map jeolla pos_left">
					<dt>
						<em>전라</em>
						<!-- <strong>5</strong> -->
					</dt>
					<!-- <dd><em>광주</em><strong>3</strong></dd> -->
				</dl>
				<dl class="list_map gyeongsang pos_right">
					<dt>
						<em>경상</em>
						<!-- <strong>18</strong> -->
					</dt>
					<!-- <dd><em>구미</em><strong>1</strong></dd> -->
					<!-- <dd><em>대구</em><strong>6</strong></dd> -->
					<!-- <dd><em>울산</em><strong>2</strong></dd> -->
					<!-- <dd><em>부산</em><strong>7</strong></dd> -->
					<!-- <dd><em>해운대</em><strong>1</strong></dd> -->
				</dl>
				<dl class="list_map jeju pos_left">
					<dt>
						<em>제주</em>
						<!-- <strong>1</strong> -->
					</dt>
				</dl>
				<img class="bg" src="/img/brand/ceo/business_map_bg.png" alt="">
				<img class="bg_line" src="/img/brand/ceo/business_map_bg_line.png" alt="">
				</div>
			</div>
		</div>
	</section>

	<!-- 전문가 -->
	<section id="network" class="section dataTarget" data-scroll-cut-line="10">
		<div class="inner">
			<h3 class="tit_section">
				전문가와 함께 최적의 솔루션을 제시 <strong>전문가 네트워크</strong>
			</h3>
			<p class="desc_section">
				중소기업을 경영할 때에는 여러 가지 위험요소가 산재합니다.<br>
				CEO클리닉은 분야별 전문가 그룹과 제휴를 맺어<br>
				<mark>자산관리 및 경영 지원에 필요한 모든 프로세스를 효율적으로 지원</mark>하고 있습니다.<br>
				전문가가 제안하는 분명하고 혁신적인 전략,<br>
				어떤 형태의 고객이라도 이 네트워크로 전방위적인 지원이 가능합니다.
			</p>
			<a class="link_more" href="/intro/Network" target="_blank">
				<span>전문가 네트워크 자세히 보기</span>
				<img src="" data-images-path="/image/svg/arr_right01.svg">
			</a>
			<ul class="list_network">
			
				<!-- <li class="item_network animate5">
					<p class="tit">세무</p>
					<p class="desc">세종TSI</p>
					<img src="/img/brand/ceo/network_model_left4.png" alt="전문가이미지">
				</li> -->
				<li class="item_network animate4">
					<p class="tit">법무</p>
					<p class="desc">가이우스<br>법률사무소</p>
					<img src="/img/brand/ceo/network_model_left3.png" alt="전문가이미지">
				</li>
				<li class="item_network animate3">
						<p class="tit">노무</p>
						<p class="desc">노무법인<br>서현</p>
					<img src="/img/brand/ceo/network_model_left2.png" alt="전문가이미지">
				</li>
				<li class="item_network animate2">
						<p class="tit">노무</p>
						<p class="desc">노무법인<br>익선</p>
					<img src="/img/brand/ceo/network_model_left1.png" alt="전문가이미지">
				</li>
				<li class="item_network animate1">
					<div class="left">
						<p class="tit">M&A</p>
						<p class="desc">한국<br>M&A거래소</p>
					</div>
					<div class="right">
						<p class="tit">기업금융</p>
						<p class="desc">IPO · 투자유치<br>· M&A자문 </p>
					</div>
					<img src="/img/brand/ceo/network_model_center.png" alt="전문가이미지">
				</li>
				<li class="item_network animate2">
					<p class="tit">부동산</p>
					<p class="desc">부동산 중개</p>
					<img src="/img/brand/ceo/network_model_right1.png" alt="전문가이미지">
				</li>
				<!-- <li class="item_network animate3">
					<p class="tit">제휴</p>
					<p class="desc">감정평가법인<br>대일감정원</p>
					<img src="/img/brand/ceo/network_model_right2.png" alt="전문가이미지">
				</li> -->
				<li class="item_network animate3">
					<p class="tit">세무</p>
					<p class="desc">세무법인 <br> 세종TSI</p>
					<img src="/img/brand/ceo/network_model_right2.png" alt="전문가이미지">
				</li>
				<!-- <li class="item_network animate5 text_excpt">
					<p class="tit">제휴</p>
					<p class="desc">청일국제특허<br> 법률사무소</p>
					<img src="/img/brand/ceo/network_model_right4.png" alt="전문가이미지">
				</li> -->
				<li class="item_network animate4">
					<p class="tit">경정청구</p>
					<p class="desc">DPBS</p>
					<img src="/img/brand/ceo/network_model_right3.png" alt="전문가이미지">
				</li>
				<li class="item_network animate5 hidden">
					<p class="tit"></p>
					<p class="desc"></p>
					<img src="/img/brand/ceo/network_model_right5.png" alt="전문가이미지">
				</li>
			</ul>
		</div>
	</section>
	<!-- //전문가 -->

    <!-- 컨설팅 흐름 -->
    <roadMap />
    <!-- //컨설팅 흐름 -->

	<!-- 솔루션 -->
	<section id="solution" class="section dataTarget" data-scroll-cut-line="10">
		<div class="inner">
			<h3 class="tit_section">
				CEO클리닉은 7가지「<strong>RISK Solution</strong>」을 제공합니다.
			</h3>
			<p class="desc_section">
				중소기업 위기극복 파트너 CEO클리닉은<br>
				기업경영의 장애물과 리스크를 최소화하고<br>
				CEO, 종업원, 회사 전반에 필요한 경영효율화 컨설팅을 제공하고 있습니다.
			</p>
			<ul class="list_solution">
				<li class="item_solution">
				<p class="tit">경영리스크<br>관리</p>
				<p class="desc">
					명의신탁주식,<br>
					가지급금,<br>
					임원 사망 대비 등
				</p>
				</li>
				<li class="item_solution">
				<p class="tit">재무분석</p>
				<p class="desc">
					기업 검진,<br>
					정관 정비,<br>
					재무구조 분석
				</p>
				</li>
				<li class="item_solution">
				<p class="tit">
					CEO<br>
					자산관리
				</p>
				<p class="desc">
					합법적 · 체계적<br>
					상속세 · 증여세 · <br>
					법인세 절세
				</p>
				</li>
				<li class="item_solution">
				<p class="tit">
					CEO<br>
					가업승계
				</p>
				<p class="desc">
					100년 기업<br>
					도약을 위한<br>
					가업승계 플랜
				</p>
				</li>
				<li class="item_solution">
				<p class="tit">금융컨설팅</p>
				<p class="desc">
					재무구조<br>
					개선을 통한<br>
					IPO, M&A
				</p>
				</li>
				<li class="item_solution">
				<p class="tit">
					운용자금<br>
					플래닝
				</p>
				<p class="desc">
					사업 확장을 위한<br>
					장 · 단기 운용자금<br>
					플래닝
				</p>
				</li>
				<li class="item_solution">
				<p class="tit">
					경영효율화<br>
					전략
				</p>
				<p class="desc">
					인사노무시스템 제안,<br>
					각종 인증 제도 및<br>
					부설연구소
				</p>
				</li>
			</ul>
		</div>
	</section>
	<!-- //솔루션 -->

	<!-- 고민해결 -->
	<section id="worry" class="section dataTarget hidden" data-scroll-cut-line="10">
		<div class="swiper-container">
			<p class="tit_container">
				CEO클리닉에서는<i></i>고민을 해결해드립니다.
			</p>
			<div class="swiper-wrapper">
				<div class="swiper-slide"></div>
			</div>
		</div>
		<div class="list_video">
			<button class="videoLayer" data-src="https://www.youtube.com/embed/KKc9rJo107k" data-title="" data-type="youtube" type="button" title="영상재생">
				<img class="thumb" src="https://i.ytimg.com/vi/KKc9rJo107k/maxresdefault.jpg" alt="스크린샷">
				<p class="tit lineClamp2">
					1. 법인의 이익금 환원! 쉽게 설명해주는 세금절약 가이드
				</p>
			</button>
			<button class="videoLayer" data-src="https://www.youtube.com/embed/DB8JuNeoqfo" data-title="" data-type="youtube" type="button" title="영상재생">
				<img class="thumb" src="https://i.ytimg.com/vi_webp/DB8JuNeoqfo/maxresdefault.webp" alt="스크린샷">
				<p class="tit lineClamp2">
					2. 가족 주주회사의 선택은, 절세의 블랙홀
				</p>
			</button>
			<button class="videoLayer" data-src="https://www.youtube.com/embed/AOEW-5xl-gM" data-title="" data-type="youtube" type="button" title="영상재생">
				<img class="thumb" src="https://i.ytimg.com/vi/AOEW-5xl-gM/maxresdefault.jpg" alt="스크린샷">
				<p class="tit lineClamp2">
					3. 사업 좀 된다 싶으면 '성실신고확인' 대상 피하는 방법!
				</p>
			</button>
			<button class="videoLayer" data-src="https://www.youtube.com/embed/SA5KiZmLjNk" data-title="" data-type="youtube" type="button" title="영상재생">
				<img class="thumb" src="https://i.ytimg.com/vi/SA5KiZmLjNk/maxresdefault.jpg" alt="스크린샷">
				<p class="tit lineClamp2">
					4. 고용지원 중 권고사직과 해고문제
				</p>
			</button>
			<button class="videoLayer" data-src="https://www.youtube.com/embed/COhWVkeDZA4" data-title="" data-type="youtube" type="button" title="영상재생">
				<img class="thumb" src="https://i.ytimg.com/vi/COhWVkeDZA4/maxresdefault.jpg" alt="스크린샷">
				<p class="tit lineClamp2">
				5	. 중소기업 정관에 꼭 들어가야 할 내용의 모든 것
				</p>
			</button>
		</div>
		<div class="more">
			<p class="tit">
				CEO클리닉 유튜브채널에서 고민해결 방법과 실제 컨설팅 사례를 직접
				확인해보세요!
			</p>
			<a class="link_more" href="https://www.youtube.com/channel/UCv127K0IeyDsPttFEw20blQ" target="_blank"><img class="ico_youtube" src="/img/brand/ceo/ico_youtube.png" alt="youtube">
				<span>채널 바로가기</span><img src="" data-images-path="/image/svg/arr_right01.svg">
			</a>
		</div>
	</section>
	<!-- //고민해결 -->

	<!-- 시작  -->
	<section id="start" class="section dataTarget" data-scroll-cut-line="10">
		<div class="contents">
			<p class="year">기업경영 시 <strong>코칭</strong>이 필요할 때!</p>
			<p class="tit">
				CEO클리닉은 언제나 고객의 자산가치를 높이기 위해 노력하고 있습니다.
			</p>
			<p class="desc">
				<span>
					급변하는 세상과 유행에 빠르게 변화하는 경제환경에 우리는 생각했습니다.<br>
					은퇴 후에도 최소 30년은 살아야 하는 초고령 사회에서</span><br>
					<span><mark>자산을 어떻게 효율적으로 관리해야할지,</mark><br>
					<mark>적절한 솔루션을 제시해준다면 얼마나 든든할까요?</mark></span><br>
					<br>
					<span><mark>급변하는 경제상황 속에서 가장 필요한 ‘최적의 솔루션’</mark><br>
					<mark>우리는 기업과 개인 고객의 안정적인 성장을 위해</mark><br>
					<mark>다양한 분야의 전문가와 함께 최적의 솔루션을 제공하기 위해 만들어졌습니다.</mark></span><br>
					<br>
					<span><mark>CEO클리닉은 십수년간 경험한 노하우로</mark><br>
					<mark>늘 기업과 함께 도약하는 경영컨설팅 브랜드입니다.</mark>
				</span>
			</p>
		</div>
		<div class="swiper-container">
			<div class="swiper-wrapper">
				<div class="swiper-slide" style="background-image: url('/img/brand/ceo/start_bg1.jpg')"></div>
				<div class="swiper-slide" style="background-image: url('/img/brand/ceo/start_bg2.jpg')"></div>
				<div class="swiper-slide" style="background-image: url('/img/brand/ceo/start_bg3.jpg')"></div>
			</div>
			<div class="swiper-nav">
				<!-- Add Pagination -->
				<div class="swiper-pagination"></div>
				<!-- Add Navigation -->
				<div class="swiper-button-next swiper-button-white"></div>
				<div class="swiper-button-prev swiper-button-white"></div>
			</div>
		</div>
	</section>
	<!-- //시작  -->

    <!-- CEO 브로슈어 -->
    <section id="ceoBrochure" class="section">
        <div class="inner">
            <h3 class="tit_section">
                <p><img class="bi" src="" data-images-path="/image/logo/ceoclinic_extension.svg" alt="로고"> <span>Brochure</span></p>
                <p>중소기업을 위한<br><strong>경영효율화 컨설팅</strong></p>
            </h3>
            <p class="desc_section">기업경영시 코칭이 필요한 이유를 지금 확인해보세요</p>
            <a class="link_download" href="" data-json-path="/pdf/brochure2024.pdf" target="_blank" download><span>브로슈어 보기</span><img src="" data-images-path="/image/svg/plus01.svg" alt="다운로드"></a>
            <a class="link_book" href="" data-json-path="/pdf/brochure2024.pdf" target="_blank" download>
                <img class="book" :src="imgPath+'ceoBrochure_book.png?v=231123'" alt="책표지">
            </a>
        </div>
    </section>
    <!-- //CEO 브로슈어 -->

	<!-- 비전제시 -->
	<section id="vision" class="section dataTarget" data-scroll-cut-line="10">
		<div class="inner">
			<h3 class="tit_section">컨설팅 신청</h3>
			<ul class="list_info">
				<li class="item_info">
					<p class="tit">임·직원</p>
					<p class="desc">단체보장플랜, 사업장 재물보험</p>
				</li>
				<li class="item_info">
					<p class="tit">회사(사업지원)</p>
					<p class="desc">특허·지적재산권, 부설연구소, 인증 ·각종제도정비</p>
				</li>
				<li class="item_info">
					<p class="tit">CEO</p>
					<p class="desc">가업 승계플랜, 증여와 상속, 기업 지배구조개선</p>
				</li>
				<li class="item_info">
					<p class="tit">회사(재무지원)</p>
					<p class="desc">
						차명주식환원, 가지급금 정리, 주식이동·배당,<br>자기주식취득,
						이익잉여금 정리
					</p>
				</li>
				<li class="item_info">
					<p class="tit">개인 보유 자산의 진단 및 분석</p>
				</li>
				<li class="item_info">
					<p class="tit">목적자금 플래닝</p>
					<p class="desc">결혼, 교육, 주택 마련, 목돈 마련 외</p>
				</li>
				<li class="item_info">
					<p class="tit">개인 사업자 법인전환 컨설팅</p>
				</li>
				<li class="item_info">
					<p class="tit">부동산 자산 토탈 컨설팅</p>
					<p class="desc">
						효율적 사전증여, 임대사업자 법인전환, 리모델링,<br>임대사업
						극대화
					</p>
				</li>
				<li class="item_info">
					<p class="tit">은퇴설계</p>
					<p class="desc">
						저금리시대 대비 투자를 통한 노후 준비, 보장자산 마련
					</p>
				</li>
			</ul>
		</div>
	</section>
	<!-- //비전제시 -->

	<!-- 상담신청  -->
	<section id="freeConsult" data-scroll-cut-line="10">
		<div class="inner">
			<div class="tab-container">
				<nav class="tab-nav">
					<button class="active" data-tab="tab-1" type="button">
						<img src="/img/common/ico/ico_building01.svg" alt=""><span>법인컨설팅</span>
					</button>
					<button data-tab="tab-2" type="button">
						<img src="/img/common/ico/ico_person01.svg" alt=""><span>개인컨설팅</span>
					</button>
				</nav>
				<div class="tab-wrapper">
					<div id="tab-1" class="tab-slide active">
						<div class="render_form"></div>
						<li class="row row_type row_css clearfix" data-type="type" data-check-pass="false"
							data-check-comment="상담분야를 선택해주세요" data-inbound-prop="codeId">
							<label class="select_wrap">
								<select name="consult" class="openSans consult select_css">
									<option value="">상담분야 선택</option>
									<option value="219">가지급금</option>
									<option value="220">명의신탁(차명주식)</option>
									<option value="221">법인설립/전환</option>
									<option value="222">자기주식</option>
									<option value="223">가업승계</option>
									<option value="224">기업제도 정비</option>
									<option value="225">이익잉여금 환원</option>
									<option value="226">배당</option>
									<option value="227">비상장주식이동</option>
									<option value="228">증여</option>
									<option value="229">기타</option>
								</select>
								<u class="select_arrow arrow_css"></u>
							</label>
						</li>
					</div>
					<div id="tab-2" class="tab-slide">
						<div class="render_form"></div>
						<!-- <li class="row row_talktime row_css clearfix" data-type="talkTime" data-check-pass="true" data-check-comment="연락 가능한 시간을 선택해주세요" data-inbound-prop="talk-time">
							<label class="select_wrap">
								<select name="consult" class="openSans consult select_css">
									<option value="언제나 통화가능" selected>언제나 통화가능</option>
									<option value="오전 09:00 ~ 10:00">오전 09:00 ~ 10:00</option>
									<option value="오전 10:00 ~ 11:00">오전 10:00 ~ 11:00</option>
									<option value="오전 11:00 ~ 12:00">오전 11:00 ~ 12:00</option>
									<option value="오후 01:00 ~ 02:00">오후 01:00 ~ 02:00</option>
									<option value="오후 02:00 ~ 03:00">오후 02:00 ~ 03:00</option>
									<option value="오후 03:00 ~ 04:00">오후 03:00 ~ 04:00</option>
									<option value="오후 04:00 ~ 05:00">오후 04:00 ~ 05:00</option>
									<option value="오후 05:00 ~ 06:00">오후 05:00 ~ 06:00</option>
								</select>
								<u class="select_arrow arrow_css"></u>
							</label>
						</li> -->
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- //상담신청  -->

	<!-- CI -->
	<section id="ci" class="section dataTarget" data-scroll-cut-line="10">
		<div class="inner">
			<h3 class="tit_section">CEO클리닉 <strong>BI소개</strong></h3>
			<div class="download">
				<div class="box_logo">
					<img src="" data-images-path="/image/logo/ceoclinic_basic.svg" alt="로고">
				</div>
				<p class="txt">
					CEO클리닉은 안정된 기반 위에 올라서는 CEO의 모습을 형상화 하였습니다.<br>
					<br>
					기업의 성공을 위한 전방위적인 솔루션 지원과 성공을 위한 로드맵을<br>
					제시하겠다는 메시지를 전달하고 있습니다.<br>
					<br>
					전체적으로 신뢰감을 주고 성공을 떠올릴 수 있는 이미지로 심플하고 임팩트<br>
					있게 표현하였습니다.
				</p>
				<div class="btn_area">
					<a class="link_download" href="" data-images-path="/data/logo/ceoclinic/jpg.zip" target="_blank" download>
						<span>JPG 다운받기</span>
						<img src="" data-images-path="/image/svg/download01.svg" alt="다운로드">
					</a>
					<a class="link_download" href="" data-images-path="/data/logo/ceoclinic/ai.zip" target="_blank" download>
						<span>AI 다운받기</span>
						<img src="" data-images-path="/image/svg/download01.svg" alt="다운로드">
					</a>
				</div>
			</div>
			<div class="system">
				<p class="tit_system">Color System</p>
				<ul class="list_system">
					<li class="item_system">
						<p class="tit">CC_BLUE</p>
						<p class="cmyk"><strong>CMYK</strong>C100 / M26 / Y0 / K0</p>
						<p class="rgb"><strong>RGB</strong>R0 / G142 / B211</p>
					</li>
					<li class="item_system">
						<p class="tit">CC_GREEN</p>
						<p class="cmyk"><strong>CMYK</strong>C53 / M0 / Y10 / K0</p>
						<p class="rgb"><strong>RGB</strong>R132 / G196 / B64</p>
					</li>
					<li class="item_system">
						<p class="tit">CC_GRAY</p>
						<p class="cmyk"><strong>CMYK</strong>C3 / M2 / Y0 / K65</p>
						<p class="rgb"><strong>RGB</strong>R87 / G88 / B90</p>
					</li>
				</ul>
			</div>
			<div class="official">
				<h3 class="tit_official">공식 이미지</h3>
				<ul class="list_official">
					<li class="item_official">
						<div class="tit">Basic</div>
						<img src="" data-images-path="/image/logo/ceoclinic_basic.svg" alt="로고">
					</li>
					<li class="item_official">
						<div class="tit">Extension</div>
						<img src="" data-images-path="/image/logo/ceoclinic_extension.svg" alt="로고">
					</li>
				</ul>
				<p class="desc_official">
					시그니쳐는 브랜드명과 사명의 조합으로 이루어진 형태를 기본으로 사용합니다.<br>
					어플리케이션에 따라 아래와 같이 확장형태의 사용이 가능합니다.<br>
					<br>
					또한 텍스트(문자)로의 CEO클리닉 브랜드명 사용시, ‘CEO클리닉 피플라이프’(전체 브랜드명) 혹은<br>
					‘CEO클리닉’(브랜드명 단독사용)으로 사용가능하며, ‘피플라이프 CEO클리닉’으로의 사용을 금합니다.
				</p>
			</div>
		</div>
	</section>
	<!-- //CI -->
</main>
<!-- /main -->
</template>


<script>
export default {
    data() {
        return {
            imgPath: '/img/brand/ceo/'
        }
    },
}
</script>



<style lang="scss" scoped>
@import "@/assets/scss/brand/ceo.scss";
</style>
